<template>
  <UiDropdown
    v-if="langAvailable.length > 0 && options.length > 1"
    id="langSwitcher"
    class="lang"
    :options="options"
    :value="currentUrl"
    :css="styles"
    @input="onChange"
  >
    <!-- slot option + label -->
  </UiDropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import UiDropdown from '@/components/ui/Dropdown.vue'
import { langAvailable } from '@/config/languages'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'
import { Language } from '@/types'

import styles from './lang.module.scss'

const chrome = useChromeStore()
const resource = useResourceStore()

const options = computed(() => {
  if (resource.languages) {
    return Object.keys(resource.languages)
      .sort()
      .map(lang => {
        const value = resource.languages[lang as Language]

        return {
          label: lang,
          value,
        }
      })
  }

  return []
})

const currentUrl = computed(() => resource.languages[chrome.language])

const onChange = (url: string) => {
  if (url) {
    window.location.href = url
  }
}
</script>

<style lang="scss" scoped>
.lang {
  margin: 1rem 1rem 1rem 4rem;
  font-size: 1.3rem;

  @include mq($until: s) {
    margin-right: 0;
  }
}
</style>
