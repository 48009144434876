<template>
  <!-- <ErrorView v-if="guardError" />
  <template v-else> -->
  <Suspense @resolve="onSuspenseResolve">
    <template v-if="isReady">
      <ChromeHeader
        v-if="!route.meta.noChrome"
        :key="route.fullPath.replace(route.hash, '')"
        :class="{ 'no-border': resource.template === 'works' }"
      />
      <main :class="resource.template" class="page">
        <Poster class="poster" />
        <RouterView v-slot="{ Component }">
          <PageTransition mode="out-in" @before-leave="onBeforeLeave">
            <component
              :is="resource.template === 'notfound' ? Notfound : Component"
              :key="route.fullPath.replace(route.hash, '')"
              :content="resource.content"
            />
          </PageTransition>
        </RouterView>
        <!-- eslint-disable max-len -->
        <!-- prettier-ignore -->
        <div data-drag-icon class="drag-icon">
          <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg" class="drag-icon__drag">
            <path d="M48.835 42.9417C48.835 42.8835 48.7476 36.3301 48.7476 35.4563V35.0485C48.7476 33.8252 47.7573 33.1553 46.767 33.0679C46.4758 33.0388 46.2136 33.0679 45.9515 33.1262C45.8641 32.0485 45.1068 31.3204 44.0583 31.3204C43.7961 31.3204 43.5631 31.3495 43.3301 31.4369C43.0971 30.6213 42.3981 30.0388 41.4952 30.0388C40.6505 30.0388 39.9515 30.5048 39.6602 31.2039C39.4563 31.1456 39.2233 31.0874 38.9612 31.0874C37.8544 31.0874 36.9515 31.9903 36.9515 33.0971V39.1262C36.4272 38.8058 35.8447 38.6602 35.233 38.6602C34.9418 38.6602 34.6505 38.6893 34.3301 38.8058C34.2136 38.8349 33.9224 38.9223 33.7767 39.0388C33.4855 39.2136 33.3107 39.4757 33.2525 39.8544C33.1942 40.1747 33.136 40.5534 33.2816 40.9029L34.5049 44.0485C34.767 44.7184 35.3495 45.5631 35.8738 46C36.9515 46.9029 37.7961 47.8058 37.9418 48.0971V48.9417C37.9418 49.5243 38.4078 49.9611 38.9612 49.9611H45.8932C46.4758 49.9611 46.9127 49.4951 46.9127 48.9417V48.0097C46.9418 47.8058 47.4661 46.6408 48.2525 45.3883C48.6602 44.6893 48.835 43.6699 48.835 42.9417ZM36.7185 44.9514C36.3398 44.6311 35.8738 43.9611 35.6991 43.5243L34.4758 40.4078C34.3884 40.2039 34.4758 40.1456 34.534 40.0874C34.7088 39.9709 34.9709 39.9709 35.2039 39.9709C35.8738 39.9709 36.4855 40.2912 36.835 40.8155L38.233 42.9709V33.0971C38.233 32.7184 38.5534 32.398 38.9321 32.398C39.3398 32.398 39.5437 32.6019 39.5728 32.9806L39.602 37.7864H40.8253L40.7961 32.0194C40.7961 31.466 41.2039 31.3786 41.4661 31.3786C41.8156 31.3786 42.0777 31.6408 42.0777 32.0194V38.2233H43.3593V33.3301C43.3593 32.8349 43.5631 32.6311 44.0292 32.6311C44.4369 32.6311 44.6117 32.8641 44.6117 33.3301V38.8349H45.8932V34.7864C45.8932 34.4951 46.1845 34.3204 46.5923 34.3495C46.767 34.3786 47.3787 34.466 47.3787 35.0194C47.3787 35.2524 47.4661 42.6213 47.4661 42.9417C47.4661 43.466 47.3204 44.2524 47.0874 44.6602C46.8253 45.0679 45.5437 47.165 45.5437 47.9514V48.6213H39.2525V47.9514C39.2525 47.1359 37.301 45.4466 36.7185 44.9514Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5088 44.5369C13.3013 57.4259 23.4232 67.6514 36.2617 69.6001L36.1116 70.5887C22.8362 68.5737 12.3719 58.0026 10.5184 44.6747L11.5088 44.5369ZM69.959 36.4482C68.1925 23.3729 57.858 13.0153 44.7939 11.2137L44.9305 10.2231C58.4393 12.086 69.1234 22.7939 70.95 36.3143L69.959 36.4482Z" />
            <path d="M67.0934 43.1249L67.3684 40.6758L74.5957 41.4871L74.3152 43.9856C74.2347 44.7031 74.0208 45.3033 73.6736 45.786C73.3285 46.2714 72.8709 46.6226 72.301 46.8398C71.7311 47.0569 71.0709 47.1234 70.3204 47.0391C69.5676 46.9546 68.9363 46.742 68.4266 46.4013C67.9167 46.0629 67.5465 45.6152 67.316 45.0581C67.0852 44.5033 67.011 43.8589 67.0934 43.1249ZM68.3542 42.1122L68.2334 43.1885C68.1769 43.692 68.2215 44.1222 68.3672 44.4792C68.5153 44.8365 68.7634 45.1205 69.1114 45.3311C69.4617 45.5419 69.9133 45.6784 70.4662 45.7404C71.0191 45.8025 71.4873 45.7693 71.871 45.6409C72.257 45.5127 72.5592 45.2929 72.7776 44.9815C72.9981 44.6727 73.1358 44.2736 73.1908 43.7843L73.3159 42.6691L68.3542 42.1122Z" />
            <path d="M66.5888 46.8721L73.4263 49.3502L72.4971 51.9142C72.3067 52.4395 72.0557 52.8471 71.7441 53.1371C71.4317 53.4293 71.0778 53.607 70.6822 53.6701C70.2881 53.7363 69.8729 53.6903 69.4366 53.5322C68.9982 53.3733 68.6514 53.1418 68.3965 52.8379C68.1429 52.537 67.9935 52.1744 67.9484 51.7501C67.9055 51.3266 67.9792 50.8522 68.1696 50.327L68.8314 48.5007L69.8597 48.8734L69.2584 50.5327C69.147 50.8399 69.0982 51.1067 69.1118 51.3332C69.1276 51.5605 69.2009 51.7508 69.3317 51.904C69.464 52.0602 69.6514 52.1822 69.894 52.2702C70.1366 52.3581 70.361 52.3853 70.567 52.3517C70.7753 52.3189 70.9595 52.2207 71.1195 52.0571C71.2817 51.8943 71.4189 51.6582 71.5311 51.3488L71.9425 50.2137L66.1399 48.1107L66.5888 46.8721ZM68.4069 51.5272L64.6952 52.097L65.1961 50.7148L68.8969 50.1751L68.4069 51.5272Z" />
            <path d="M63.8808 53.4601L64.653 52.2849L69.3251 58.4184L68.4319 59.7776L60.9459 57.9267L61.7182 56.7514L67.6164 58.3027L67.6476 58.2552L63.8808 53.4601ZM66.2386 55.0646L64.1325 58.2698L63.2481 57.6887L65.3542 54.4835L66.2386 55.0646Z" />
            <path d="M60.703 64.7797C60.8758 64.8817 61.0518 64.9537 61.2308 64.9957C61.4096 65.041 61.5891 65.0554 61.7692 65.0387C61.9476 65.0237 62.1234 64.9742 62.2966 64.8902C62.4714 64.808 62.6394 64.6936 62.8005 64.547C63.0894 64.2842 63.2808 63.9773 63.3744 63.6264C63.4681 63.2756 63.4515 62.9002 63.3246 62.5004C63.1975 62.1039 62.9508 61.7043 62.5844 61.3015C62.2148 60.8952 61.8378 60.6092 61.4535 60.4436C61.0692 60.278 60.6963 60.2251 60.3348 60.2851C59.9749 60.3469 59.6444 60.5148 59.3432 60.7888C59.07 61.0373 58.8814 61.3049 58.7773 61.5916C58.6715 61.8799 58.6519 62.1697 58.7186 62.4611C58.7869 62.7543 58.9397 63.0313 59.177 63.2923L58.9253 63.4588L60.3858 62.1302L61.0788 62.892L58.896 64.8778L58.3081 64.2316C57.8891 63.771 57.6248 63.2834 57.515 62.7687C57.4053 62.2541 57.4392 61.7496 57.6167 61.2552C57.7959 60.7626 58.1079 60.3139 58.5527 59.9093C59.0482 59.4584 59.5846 59.1737 60.1617 59.0552C60.7386 58.94 61.3193 58.9926 61.9038 59.213C62.4882 59.4367 63.0385 59.8323 63.5546 60.3996C63.9497 60.8339 64.2413 61.2776 64.4294 61.7306C64.6157 62.1852 64.706 62.6327 64.7002 63.0732C64.6961 63.5155 64.6009 63.935 64.4145 64.3317C64.2298 64.7302 63.9614 65.0896 63.6094 65.4098C63.3117 65.6806 62.9943 65.8893 62.6572 66.036C62.3217 66.1843 61.9817 66.2697 61.6371 66.2919C61.2907 66.3158 60.9532 66.2772 60.6244 66.1762C60.2957 66.0752 59.9909 65.9108 59.7101 65.683L60.703 64.7797Z" />
            <path d="M57.0015 70.273L55.5097 70.8843L51.5135 66.8268L51.4346 66.8591L51.4351 72.5541L49.9433 73.1655L47.1855 66.4359L48.3553 65.9565L50.2499 70.5798L50.3124 70.5542L50.2856 65.1885L51.1597 64.8303L54.9101 68.6816L54.9725 68.656L53.0738 64.0228L54.2436 63.5434L57.0015 70.273Z" />
            <path d="M46.6215 66.3733L47.6269 73.5762L42.9422 74.23L42.7895 73.1362L46.1694 72.6645L45.8964 70.709L42.7592 71.1469L42.6066 70.0531L45.7438 69.6152L45.4694 67.6492L42.0614 68.1249L41.9087 67.031L46.6215 66.3733Z" />
            <path d="M14.3589 37.7463L14.084 40.1954L6.85669 39.3842L7.13716 36.8856C7.21771 36.1681 7.43159 35.5679 7.7788 35.0852C8.12392 34.5998 8.58144 34.2486 9.15136 34.0314C9.72128 33.8143 10.3815 33.7479 11.132 33.8321C11.8848 33.9166 12.5161 34.1292 13.0258 34.4699C13.5357 34.8083 13.9059 35.256 14.1364 35.8131C14.3672 36.3679 14.4413 37.0123 14.3589 37.7463ZM13.0982 38.759L13.219 37.6827C13.2755 37.1792 13.2309 36.749 13.0852 36.392C12.9371 36.0347 12.689 35.7507 12.341 35.5401C11.9907 35.3293 11.5391 35.1928 10.9862 35.1308C10.4333 35.0687 9.96506 35.1019 9.58138 35.2304C9.19536 35.3586 8.89315 35.5783 8.67476 35.8897C8.45428 36.1985 8.31657 36.5976 8.26164 37.0869L8.13646 38.2021L13.0982 38.759Z" />
            <path d="M14.8636 33.9991L8.02605 31.521L8.95534 28.957C9.14571 28.4317 9.39669 28.0241 9.70827 27.7341C10.0207 27.4419 10.3746 27.2642 10.7702 27.2011C11.1643 27.1349 11.5795 27.1809 12.0158 27.339C12.4542 27.4979 12.801 27.7294 13.0559 28.0333C13.3095 28.3342 13.4588 28.6968 13.504 29.1211C13.5469 29.5446 13.4732 30.019 13.2828 30.5442L12.621 32.3705L11.5927 31.9978L12.194 30.3385C12.3054 30.0313 12.3542 29.7645 12.3406 29.538C12.3248 29.3107 12.2515 29.1204 12.1207 28.9672C11.9884 28.811 11.801 28.689 11.5584 28.601C11.3158 28.5131 11.0914 28.4859 10.8854 28.5195C10.6771 28.5523 10.4929 28.6505 10.3329 28.8141C10.1707 28.9769 10.0335 29.213 9.92134 29.5224L9.50994 30.6575L15.3125 32.7605L14.8636 33.9991ZM13.0455 29.344L16.7572 28.7742L16.2563 30.1564L12.5555 30.6961L13.0455 29.344Z" />
            <path d="M17.5716 27.4111L16.7994 28.5863L12.1273 22.4528L13.0205 21.0936L20.5065 22.9446L19.7342 24.1198L13.836 22.5685L13.8048 22.616L17.5716 27.4111ZM15.2138 25.8066L17.3199 22.6014L18.2043 23.1825L16.0982 26.3877L15.2138 25.8066Z" />
            <path d="M20.7494 16.0915C20.5766 15.9895 20.4006 15.9175 20.2216 15.8755C20.0428 15.8302 19.8633 15.8158 19.6832 15.8325C19.5048 15.8475 19.329 15.897 19.1558 15.981C18.981 16.0632 18.813 16.1776 18.6519 16.3242C18.3629 16.5871 18.1716 16.8939 18.078 17.2448C17.9843 17.5956 18.0009 17.971 18.1278 18.3708C18.2549 18.7673 18.5016 19.1669 18.868 19.5697C19.2376 19.976 19.6146 20.262 19.9989 20.4276C20.3832 20.5933 20.7561 20.6461 21.1176 20.5861C21.4775 20.5243 21.808 20.3564 22.1092 20.0824C22.3824 19.8339 22.571 19.5663 22.6751 19.2796C22.7809 18.9913 22.8005 18.7015 22.7338 18.4101C22.6655 18.1169 22.5127 17.8399 22.2754 17.579L22.5271 17.4124L21.0666 18.741L20.3736 17.9792L22.5564 15.9934L23.1443 16.6396C23.5633 17.1002 23.8276 17.5878 23.9374 18.1025C24.0471 18.6171 24.0132 19.1217 23.8357 19.616C23.6565 20.1086 23.3445 20.5573 22.8997 20.9619C22.4041 21.4128 21.8678 21.6975 21.2907 21.816C20.7138 21.9312 20.1331 21.8786 19.5486 21.6582C18.9642 21.4345 18.4139 21.039 17.8978 20.4716C17.5027 20.0373 17.2111 19.5936 17.023 19.1406C16.8367 18.686 16.7464 18.2385 16.7522 17.798C16.7563 17.3557 16.8515 16.9363 17.0379 16.5395C17.2226 16.141 17.491 15.7816 17.843 15.4614C18.1407 15.1906 18.4581 14.9819 18.7952 14.8352C19.1307 14.6869 19.4707 14.6015 19.8153 14.5793C20.1617 14.5554 20.4992 14.594 20.828 14.695C21.1567 14.796 21.4615 14.9604 21.7423 15.1882L20.7494 16.0915Z" />
            <path d="M24.4509 10.5982L25.9427 9.98685L29.9389 14.0444L30.0178 14.0121L30.0173 8.31707L31.5091 7.70571L34.2669 14.4353L33.0971 14.9147L31.2025 10.2914L31.14 10.317L31.1668 15.6827L30.2927 16.0409L26.5423 12.1896L26.4799 12.2152L28.3786 16.8484L27.2088 17.3278L24.4509 10.5982Z" />
            <path d="M34.8309 14.4979L33.8255 7.29503L38.5102 6.64117L38.6629 7.73498L35.283 8.20671L35.556 10.1622L38.6932 9.72432L38.8458 10.8181L35.7086 11.256L35.983 13.222L39.391 12.7464L39.5437 13.8402L34.8309 14.4979Z" />
          </svg>

          <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg" class="drag-icon__dragging">
            <path d="M45.2136 35.7475C44.9805 35.7475 44.7475 35.7767 44.5145 35.8641C44.0485 35.4563 43.4369 35.2233 42.7961 35.1941H42.7378C42.7087 35.1941 42.6796 35.1941 42.6504 35.1941C42.4466 35.2233 42.2136 35.2524 42.0388 35.3398C41.5436 34.932 40.932 34.699 40.2912 34.699C40.0582 34.699 39.8252 34.7573 39.5922 34.8446C39.097 34.4369 38.4854 34.2039 37.8446 34.2039C36.7669 34.2039 35.8932 35.0776 35.8932 36.1844V36.767C34.2621 36.8543 32.9514 38.2233 32.9514 39.9126V43.4369C32.9514 44.3398 33.4757 45.3592 34.2038 45.9126C35.3689 46.7573 36.5631 47.7767 36.8835 48.2136V49.0582C36.8835 49.6699 37.3786 50.1941 38.0194 50.1941H44.8932C45.5048 50.1941 46.0291 49.699 46.0291 49.0582V48.0679C46.0582 47.7767 46.6116 46.6699 47.2815 45.5922L47.3106 45.5631C47.6893 44.9223 47.9805 43.9029 47.9805 43.1747V38.5728C47.9805 37.0291 46.7281 35.7475 45.2136 35.7475ZM39.5048 37.0582H39.6504V36.7087C39.6504 36.3301 39.9417 36.0097 40.3203 36.0097C41.1359 36.0097 41.8058 36.7087 41.8058 37.5534H42.097V37.2039C42.097 36.8252 42.3883 36.5048 42.7669 36.4757C43.6116 36.5048 44.2524 37.2039 44.2524 38.0485H44.5436V37.699C44.5436 37.3204 44.8349 37 45.2136 37C46.0291 37 46.699 37.699 46.699 38.5437V43.1747C46.699 43.699 46.466 44.4563 46.2038 44.9223C46.1456 45.0097 44.7475 47.2233 44.7475 48.0388V48.9126H38.165V48.0388C38.165 47.1941 35.4854 45.2718 34.9611 44.8932C34.5534 44.6019 34.233 43.9611 34.233 43.4369V39.9417C34.233 38.9514 34.9611 38.1359 35.8932 38.0485V41.1068H37.1747V36.2136C37.1747 35.8349 37.466 35.5145 37.8446 35.5145C38.6602 35.5145 39.3301 36.2136 39.3301 37.0582H39.5048Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9007 44.3164C12.6932 57.2054 22.8151 67.4309 35.6535 69.3795L35.5035 70.3682C22.228 68.3532 11.7637 57.7821 9.91022 44.4542L10.9007 44.3164ZM69.3508 36.2277C67.5844 23.1524 57.2498 12.7947 44.1857 10.9932L44.3224 10.0026C57.8312 11.8654 68.5152 22.5734 70.3418 36.0938L69.3508 36.2277Z" />
            <path d="M66.485 42.9043L66.76 40.4552L73.9873 41.2665L73.7068 43.765C73.6263 44.4826 73.4124 45.0828 73.0652 45.5655C72.7201 46.0509 72.2626 46.4021 71.6926 46.6192C71.1227 46.8364 70.4625 46.9028 69.712 46.8186C68.9592 46.7341 68.3279 46.5215 67.8182 46.1808C67.3083 45.8424 66.9381 45.3947 66.7076 44.8375C66.4768 44.2828 66.4026 43.6384 66.485 42.9043ZM67.7458 41.8916L67.625 42.968C67.5685 43.4715 67.6131 43.9017 67.7588 44.2587C67.9069 44.616 68.155 44.9 68.503 45.1105C68.8533 45.3214 69.3049 45.4579 69.8578 45.5199C70.4107 45.582 70.8789 45.5488 71.2626 45.4203C71.6486 45.2921 71.9508 45.0723 72.1692 44.761C72.3897 44.4522 72.5274 44.0531 72.5824 43.5638L72.7075 42.4486L67.7458 41.8916Z" />
            <path d="M65.9804 46.6516L72.8179 49.1296L71.8887 51.6937C71.6983 52.219 71.4473 52.6266 71.1357 52.9166C70.8233 53.2088 70.4694 53.3865 70.0738 53.4496C69.6797 53.5157 69.2645 53.4698 68.8282 53.3117C68.3898 53.1527 68.043 52.9213 67.7881 52.6174C67.5345 52.3165 67.3851 51.9539 67.34 51.5296C67.2971 51.1061 67.3708 50.6317 67.5612 50.1064L68.223 48.2802L69.2513 48.6529L68.65 50.3122C68.5386 50.6193 68.4898 50.8862 68.5034 51.1127C68.5192 51.34 68.5925 51.5303 68.7233 51.6834C68.8556 51.8397 69.043 51.9617 69.2856 52.0497C69.5282 52.1376 69.7526 52.1647 69.9586 52.1312C70.1669 52.0984 70.3511 52.0002 70.5111 51.8366C70.6733 51.6738 70.8105 51.4377 70.9227 51.1283L71.3341 49.9932L65.5315 47.8902L65.9804 46.6516ZM67.7985 51.3067L64.0868 51.8765L64.5877 50.4943L68.2885 49.9546L67.7985 51.3067Z" />
            <path d="M63.2724 53.2396L64.0446 52.0643L68.7167 58.1978L67.8235 59.5571L60.3375 57.7061L61.1098 56.5309L67.008 58.0822L67.0392 58.0347L63.2724 53.2396ZM65.6302 54.8441L63.5241 58.0493L62.6397 57.4682L64.7458 54.263L65.6302 54.8441Z" />
            <path d="M60.0946 64.5592C60.2674 64.6612 60.4434 64.7332 60.6224 64.7752C60.8012 64.8205 60.9807 64.8348 61.1608 64.8182C61.3392 64.8032 61.515 64.7537 61.6882 64.6697C61.863 64.5875 62.031 64.4731 62.1921 64.3265C62.481 64.0636 62.6724 63.7568 62.766 63.4059C62.8597 63.055 62.8431 62.6797 62.7162 62.2799C62.5891 61.8834 62.3424 61.4837 61.976 61.081C61.6064 60.6747 61.2294 60.3887 60.8451 60.2231C60.4608 60.0574 60.0879 60.0046 59.7264 60.0646C59.3665 60.1264 59.036 60.2943 58.7348 60.5683C58.4616 60.8168 58.273 61.0844 58.1689 61.3711C58.0631 61.6594 58.0435 61.9492 58.1102 62.2406C58.1785 62.5338 58.3313 62.8108 58.5686 63.0717L58.3169 63.2383L59.7774 61.9097L60.4704 62.6714L58.2876 64.6572L57.6997 64.0111C57.2807 63.5505 57.0164 63.0629 56.9066 62.5482C56.7969 62.0336 56.8308 61.529 57.0083 61.0347C57.1875 60.542 57.4995 60.0934 57.9443 59.6888C58.4398 59.2379 58.9762 58.9532 59.5533 58.8347C60.1302 58.7195 60.7109 58.7721 61.2954 58.9925C61.8798 59.2162 62.4301 59.6117 62.9462 60.1791C63.3413 60.6134 63.6329 61.0571 63.821 61.5101C64.0073 61.9647 64.0976 62.4122 64.0919 62.8527C64.0877 63.2949 63.9925 63.7144 63.8061 64.1112C63.6214 64.5097 63.353 64.8691 63.001 65.1893C62.7033 65.4601 62.3859 65.6688 62.0488 65.8155C61.7133 65.9638 61.3733 66.0491 61.0287 66.0714C60.6823 66.0952 60.3448 66.0567 60.016 65.9557C59.6873 65.8546 59.3825 65.6902 59.1017 65.4625L60.0946 64.5592Z" />
            <path d="M56.3931 70.0525L54.9013 70.6638L50.9051 66.6063L50.8262 66.6386L50.8267 72.3336L49.3349 72.945L46.5771 66.2154L47.7469 65.736L49.6415 70.3593L49.704 70.3337L49.6772 64.968L50.5513 64.6098L54.3017 68.461L54.3641 68.4354L52.4654 63.8023L53.6352 63.3229L56.3931 70.0525Z" />
            <path d="M46.0131 66.1527L47.0185 73.3557L42.3338 74.0095L42.1811 72.9157L45.561 72.444L45.288 70.4885L42.1508 70.9264L41.9982 69.8326L45.1354 69.3947L44.861 67.4287L41.453 67.9043L41.3003 66.8105L46.0131 66.1527Z" />
            <path d="M13.7505 37.5258L13.4756 39.9749L6.24829 39.1636L6.52876 36.6651C6.60931 35.9476 6.82319 35.3474 7.1704 34.8647C7.51552 34.3793 7.97304 34.0281 8.54296 33.8109C9.11288 33.5938 9.77309 33.5273 10.5236 33.6116C11.2764 33.6961 11.9077 33.9087 12.4174 34.2494C12.9273 34.5878 13.2975 35.0355 13.528 35.5926C13.7588 36.1474 13.8329 36.7918 13.7505 37.5258ZM12.4898 38.5385L12.6106 37.4622C12.6671 36.9587 12.6225 36.5285 12.4768 36.1714C12.3287 35.8141 12.0806 35.5302 11.7326 35.3196C11.3823 35.1088 10.9307 34.9723 10.3778 34.9102C9.82493 34.8482 9.35666 34.8814 8.97298 35.0098C8.58696 35.138 8.28475 35.3578 8.06636 35.6692C7.84588 35.978 7.70817 36.377 7.65324 36.8664L7.52806 37.9815L12.4898 38.5385Z" />
            <path d="M14.2552 33.7786L7.41765 31.3005L8.34694 28.7364C8.53731 28.2112 8.78829 27.8035 9.09987 27.5136C9.41225 27.2214 9.76623 27.0437 10.1618 26.9806C10.5559 26.9144 10.9711 26.9604 11.4074 27.1185C11.8458 27.2774 12.1926 27.5088 12.4475 27.8128C12.7011 28.1137 12.8504 28.4763 12.8956 28.9006C12.9385 29.3241 12.8648 29.7985 12.6744 30.3237L12.0126 32.15L10.9843 31.7773L11.5856 30.118C11.697 29.8108 11.7458 29.544 11.7322 29.3175C11.7164 29.0901 11.6431 28.8999 11.5123 28.7467C11.38 28.5905 11.1926 28.4684 10.95 28.3805C10.7074 28.2926 10.483 28.2654 10.277 28.299C10.0687 28.3318 9.88452 28.43 9.7245 28.5936C9.56226 28.7564 9.42507 28.9925 9.31294 29.3019L8.90154 30.437L14.7041 32.54L14.2552 33.7786ZM12.4371 29.1235L16.1488 28.5537L15.6479 29.9358L11.9471 30.4756L12.4371 29.1235Z" />
            <path d="M16.9632 27.1906L16.191 28.3658L11.5189 22.2323L12.4121 20.8731L19.8981 22.724L19.1258 23.8993L13.2276 22.348L13.1964 22.3955L16.9632 27.1906ZM14.6054 25.5861L16.7115 22.3809L17.5959 22.962L15.4898 26.1672L14.6054 25.5861Z" />
            <path d="M20.141 15.871C19.9682 15.769 19.7922 15.697 19.6132 15.655C19.4344 15.6097 19.2549 15.5953 19.0748 15.612C18.8964 15.627 18.7206 15.6765 18.5474 15.7605C18.3726 15.8427 18.2046 15.9571 18.0435 16.1037C17.7545 16.3665 17.5632 16.6734 17.4696 17.0243C17.3759 17.3751 17.3925 17.7505 17.5194 18.1503C17.6465 18.5468 17.8932 18.9464 18.2596 19.3492C18.6292 19.7555 19.0062 20.0414 19.3905 20.2071C19.7748 20.3727 20.1477 20.4256 20.5092 20.3656C20.8691 20.3038 21.1996 20.1359 21.5008 19.8619C21.774 19.6134 21.9626 19.3458 22.0667 19.0591C22.1725 18.7708 22.1921 18.481 22.1254 18.1896C22.0571 17.8964 21.9043 17.6194 21.667 17.3584L21.9187 17.1919L20.4582 18.5205L19.7652 17.7587L21.948 15.7729L22.5359 16.4191C22.9549 16.8797 23.2192 17.3673 23.329 17.882C23.4387 18.3966 23.4048 18.9011 23.2273 19.3955C23.0481 19.8881 22.7361 20.3368 22.2913 20.7414C21.7957 21.1923 21.2594 21.4769 20.6823 21.5955C20.1054 21.7106 19.5247 21.658 18.9402 21.4377C18.3558 21.214 17.8055 20.8184 17.2894 20.251C16.8943 19.8167 16.6027 19.3731 16.4146 18.9201C16.2283 18.4655 16.138 18.018 16.1438 17.5775C16.1479 17.1352 16.2431 16.7157 16.4295 16.319C16.6142 15.9205 16.8826 15.5611 17.2346 15.2409C17.5323 14.9701 17.8497 14.7613 18.1868 14.6147C18.5223 14.4663 18.8623 14.381 19.2069 14.3588C19.5533 14.3349 19.8908 14.3735 20.2196 14.4745C20.5483 14.5755 20.8531 14.7399 21.1339 14.9677L20.141 15.871Z" />
            <path d="M23.8425 10.3777L25.3343 9.76633L29.3305 13.8239L29.4094 13.7916L29.4089 8.09655L30.9007 7.48519L33.6585 14.2147L32.4887 14.6941L30.5941 10.0709L30.5316 10.0964L30.5584 15.4622L29.6843 15.8204L25.9339 11.9691L25.8715 11.9947L27.7702 16.6279L26.6004 17.1072L23.8425 10.3777Z" />
            <path d="M34.2225 14.2774L33.2171 7.07451L37.9018 6.42065L38.0545 7.51446L34.6746 7.98619L34.9476 9.94167L38.0848 9.5038L38.2374 10.5976L35.1002 11.0355L35.3746 13.0015L38.7826 12.5258L38.9353 13.6196L34.2225 14.2774Z" />
          </svg>
          <!-- eslint-enable max-len -->
        </div>
      </main>
      <ChromeFooter v-if="!route.meta.noChrome" />
    </template>
  </Suspense>
  <!-- dev zone -->
  <DevEnvSwitcher />
  <DevGrid />
  <!-- </template> -->
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import { HeadAttrs, useHead, useHeadRaw } from '@vueuse/head'
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import { googleSite, ua } from '@/config/app'
import favicon from '@/config/favicon'
import { prefers } from '@/core/prefers'
import { updateResource } from '@/core/resource'
// import { guardError } from '@/router/guards'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'
import { useResourceStore, ResourceSingle } from '@/stores/resource'
import { useUiStore } from '@/stores/ui'
import { useResponsive, useViewport } from '@/utils/aware'
import { logger } from '@/utils/logger'
import { push, DataLayer } from '@/utils/tracking'

import PageTransition from '@/animations/PageTransition.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import DevEnvSwitcher from '@/components/dev/EnvSwitcher.vue'
import DevGrid from '@/components/dev/Grid.vue'
import Poster from '@/components/global/Poster.vue'
// import ErrorView from '@/views/Error.vue'
import { swapColors, setColors } from '@/modules/colors'
import { wait } from '@/utils/async'
import Notfound from '@/views/404.vue'

gsap.registerPlugin(Observer)

const isReady = ref(import.meta.env.SSR)
const ui = useUiStore()
const chrome = useChromeStore()
const navigation = useNavigationStore()
const resource = useResourceStore()
const route = useRoute()

const onSuspenseResolve = () => {
  isReady.value = true
}

useViewport()

if (!import.meta.env.SSR) {
  prefers.init()
  logger.log('prefers', prefers)
}

logger.log('App setup')

// Send performance server timing data
if (!import.meta.env.SSR) {
  const { performance } = window
  // TODO: check if this is safe (rather than entry.name === location.href)
  const [currentEntry] = performance.getEntriesByType(
    'navigation'
  ) as PerformanceNavigationTiming[]

  if (currentEntry?.serverTiming) {
    const { serverTiming } = currentEntry
    const layer: DataLayer = { event: 'server_performance' }

    serverTiming.forEach(({ name, duration }) => {
      layer[name] = duration
    })

    push(layer)
  }
}

// First rendering, SSR
if (import.meta.env.SSR && route.meta.state) {
  updateResource(route.meta.state.resource as ResourceSingle)
}

/**
 * UA
 */
logger.log('UA', ua)
if (!import.meta.env.SSR && ua) {
  const device = ua.getDevice()
  const os = ua.getOS()

  document.body.dataset.deviceType = device.type
  document.body.dataset.osName = os.name
}

/**
 * Head
 */

const title = computed(
  () => resource.head?.title || resource.content?.title || ''
)
const meta = computed(() => {
  const meta = [...(resource.head?.meta || []), ...favicon.meta]

  meta.push({ name: 'environment', content: import.meta.env.VITE_RELEASE })

  googleSite &&
    meta.push({ name: 'google-site-verification', content: googleSite })

  return meta
})

const preload: HeadAttrs[] = []

// Case poster
if (resource.template === 'case-study' && resource.content?.visual?.base) {
  preload.push({
    rel: 'preload',
    as: 'image',
    href: resource.content.visual.base.sets[2560],
    // media: '(max-width: 600px)',
    imagesrcset: `
        ${resource.content.visual.base.sets[2560]} 2560w,
        ${resource.content.visual.base.sets[1280]} 1280w,
        ${resource.content.visual.base.sets[960]} 960w`,
    imagesizes: '100vw',
    crossorigin: true,
  })
}

const link = computed(() => {
  // const { link } = favicon
  const link = [...(resource.head?.link || []), ...favicon.link]

  return (
    link
      // Preconnect CMS
      // <link rel="preconnect" href="https://foo.com/" crossorigin>
      .concat({
        rel: 'preconnect',
        href: import.meta.env.VITE_API_DOMAIN,
        crossorigin: true,
      })
      .concat(preload)
  )
})

// Do this once… no reactivity
const scriptRaw: HeadAttrs[] = []

if (import.meta.env.SSR) {
  chrome.script.forEach(s => {
    if (s.type === 'application/ld+json') {
      // Convert object to prettified JSON string
      // this is injected once on SSR
      s.innerHTML = JSON.stringify(s.children)

      scriptRaw.push(s)
    }
  })
}

const script = computed(() => [
  ...chrome.script.filter(s => s.type !== 'application/ld+json'),
  {
    src: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
    async: true,
  },
])

useHead({
  title,
  meta,
  link,
  script,
  // htmlAttrs: { lang: 'en' },
  // bodyAttrs: { class: 'dummy test' },
  // script: [
  //   {
  //     type: 'application/ld+json',
  //     children: JSON.stringify({ something: true }),
  //   },
  // ],
})
useHeadRaw({
  script: scriptRaw,
})

const setDefaultColors = () => {
  if (!resource.content.colors) {
    return
  }

  swapColors(resource.content.colors)
}

const getDocumentColors = () => ({
  background: document.documentElement.style.getPropertyValue('--c-background'),
  foreground: document.documentElement.style.getPropertyValue('--c-foreground'),
})

const updateColors = async (resource: ResourceSingle) => {
  let colors = resource.content.colors || getDocumentColors()

  if (resource.template === 'home') {
    // How to mange 'shuffle' sooner…
    colors = getDocumentColors()
  }

  await wait(navigation.trigger === 'megamenu' ? 0.75 : 0.5)
  setColors(colors)
}

const setDataTemplate = (template = 'none') => {
  document.body.dataset.template = template
}

const background = ref(resource?.content?.colors?.background)
const browserThemeColors = reactive({
  meta: [
    {
      name: 'theme-color',
      content: background,
    },
    {
      name: 'msapplication-navbutton-color',
      content: background,
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: background,
    },
  ],
})

useResponsive('greater', 's', value => {
  ui.isSmallLarger = value
})
useResponsive('greater', 'm', value => {
  ui.isMediumLarger = value
})

useHead(browserThemeColors)

onBeforeMount(() => {
  setDefaultColors()
})

onMounted(() => {
  logger.log('App mounted')

  const obs = new MutationObserver(mutations => {
    const currentValue = (
      mutations[0].target as HTMLElement
    ).style.getPropertyValue('--c-background')
    const menuColor = (
      mutations[0].target as HTMLElement
    ).style.getPropertyValue('--c-menu-background')

    if (currentValue !== background.value || menuColor.length) {
      background.value = menuColor.length ? menuColor : currentValue
    }
  })

  obs.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['style'],
  })

  document.addEventListener('cookieShowSelection', () => {
    ui.toggleScroll(false)
  })
  document.addEventListener('cookieHideSelection', () => {
    ui.toggleScroll(true)
  })

  // await updateBrowserThemeColor()
  setDataTemplate(resource.template)

  const event = new Event('appready')
  setTimeout(() => document.dispatchEvent(event), 0)
})

const onBeforeLeave = async () => {
  // Update state during the transition
  // when the previous view is leaving to avoid content errors.
  if (route.meta.state) {
    updateResource(route.meta.state.resource as ResourceSingle)
    setDataTemplate(route.meta.state.resource?.template)
    await updateColors(route.meta.state.resource as ResourceSingle)
    navigation.trigger = undefined
  }
}
</script>

<style lang="scss" scoped>
.poster {
  position: fixed;
  z-index: -1;
  inset: 0;
}

.drag-icon {
  position: fixed;
  z-index: 20000; // Override draggable zboost
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  background-color: var(--c-background);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.drag-icon__drag,
.drag-icon__dragging {
  @include get-all-space;

  fill: var(--c-foreground);
}

.drag-icon__drag {
  .is-pressed & {
    opacity: 0;
  }
}

.drag-icon__dragging {
  opacity: 0;

  .is-pressed & {
    opacity: 1;
  }
}
</style>
<style lang="scss">
.app {
  padding-top: $header-height;
}
</style>
